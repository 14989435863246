import { createSlice } from '@reduxjs/toolkit';
import marksItems from '../../utils/marksItems';

const checkoutSlice = createSlice({
  name: 'checkout',

  initialState: {
    item: null,
    marksItem: false,
    lock: false,
    loading: false,

    ccaForm: null,

    form: {
      country: 'IN_India_91',
      state: null,
      city: null,
      class: null,
    },

    coupon: null,
    isCouponReferral: null,

    originalPrice: null,
    discountedPrice: null,

    error: false,
    message: '',

    focusField: null,
    focusFieldMessage: '',
  },

  reducers: {
    initalizeItem(state, action) {
      const { item, loading } = action.payload;
      state.item = item;
      state.loading = loading;
      if (item) {
        state.originalPrice = item.discountedPrice;
        if (marksItems.includes(item.itemType)) state.marksItem = true;
      }
    },

    couponCheck(state, action) {
      const { originalPrice, discountedPrice, coupon, error, message, lock, isCouponReferral } = action.payload;
      state.originalPrice = Number.parseInt(originalPrice);
      state.discountedPrice = Number.parseInt(discountedPrice);
      state.coupon = coupon;
      state.error = error;
      state.message = message;
      state.lock = lock;
      state.isCouponReferral = isCouponReferral;
    },

    toggleLoading(state, action) {
      state.loading = action.payload;
    },

    updateCCAForm(state, action) {
      state.ccaForm = action.payload;
    },

    updateCoupon(state, action) {
      const c = action.payload.coupon;
      state.coupon = c.toUpperCase();
      if (c === '') {
        state.error = false;
        state.message = '';
      }
    },

    updateCountry(state, action) {
      state.form = {
        country: action.payload,
        state: null,
        city: null,
      };
    },

    updateState(state, action) {
      state.form = {
        ...state.form,
        state: action.payload,
        city: null,
      };
    },

    updateCity(state, action) {
      state.form = { ...state.form, city: action.payload };
    },

    updateClass(state, action) {
      state.form = { ...state.form, class: action.payload };
    },

    setFocusField(state, action) {
      state.focusField = action.payload.focusField;
      if (action.payload.focusFieldMessage) state.focusFieldMessage = action.payload.focusFieldMessage;
    },
  },
});

export const checkoutActions = checkoutSlice.actions;

export default checkoutSlice;
