import { createSlice } from '@reduxjs/toolkit';
import cogoToast from 'cogo-toast';

const uiSlice = createSlice({
  name: 'ui',

  initialState: { notification: null, visibleGSTDrawer: false },

  reducers: {
    showNotification(state, action) {
      const { status, message } = action.payload;

      switch (status) {
        case 'success':
          cogoToast.success(message, {
            position: 'top-right',
          });
          break;

        case 'info':
          cogoToast.info(message, {
            position: 'top-right',
          });
          break;

        case 'loading':
          cogoToast.loading(message, {
            position: 'top-right',
          });
          break;

        case 'warn':
          cogoToast.warn(message, {
            position: 'top-right',
          });
          break;

        case 'error':
          cogoToast.error(message, {
            position: 'top-right',
          });
          break;

        default:
          break;
      }

      state.notification = {
        status,
        message,
      };
    },

    toggleGSTDrawer(state, action) {
      state.visibleGSTDrawer = action.payload;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;
