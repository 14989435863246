import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Descriptions, Drawer } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { uiActions } from '../../store/reducers/ui-slice';

const LeftPanel = () => {
  const dispatch = useDispatch();

  const [discount, item, form] = useSelector((state) => [
    state.checkout.discountedPrice,
    state.checkout.item,
    state.checkout.form,
  ]);
  const [isGSTDrawerVisible] = useSelector((state) => [state.ui.visibleGSTDrawer]);

  let state, country;
  if (form) {
    state = form.state;
    country = form.country;
  }

  const { title, description, discountedPrice: originalPrice, defaultCoupon, specialText, validity } = item;

  const discountAmt = discount ? originalPrice - discount : 0;
  const finalPrice = discount ? discount : originalPrice;
  const basePrice = finalPrice / 1.18;
  const gstAmt = Math.round(finalPrice - basePrice);

  const showGSTDrawerHandler = () => {
    dispatch(uiActions.toggleGSTDrawer(true));
  };

  const hideGSTDrawerHandler = () => {
    dispatch(uiActions.toggleGSTDrawer(false));
  };

  return (
    <div className="col-12 col-lg-5 text-center text-lg-left ml-auto">
      <h2 className="mb-0 font-weight-bold">{title}</h2>
      <p className="mb-4 text-muted">{description}</p>

      <Descriptions
        bordered
        className="mb-6 text-left"
        style={{ width: '95%' }}
        column={1}
        layout={window.innerWidth > 400 ? 'horizontal' : 'vertical'}
      >
        <Descriptions.Item label="Valid Till">
          <span className="h5 p-0" style={{ fontSize: 16 }}>
            {validity}
          </span>
        </Descriptions.Item>

        <Descriptions.Item label="Price (all inclusive)">
          <span className="h5 p-2">&#8377;{originalPrice}</span>
        </Descriptions.Item>

        {discount && (
          <Descriptions.Item label="Discount">
            <span className="h5 font-weight-bold p-2" style={{ color: 'green' }}>
              &#8211; &#8377;{discountAmt}
            </span>
          </Descriptions.Item>
        )}

        <Descriptions.Item
          label={
            <>
              Final Price
              {country === 'IN_India' ? (
                <span className="h5 font-weight-bold p-2">
                  <InfoCircleOutlined size={24} style={{ verticalAlign: 'baseline' }} onClick={showGSTDrawerHandler} />
                </span>
              ) : null}
            </>
          }
        >
          <span className="h5 font-weight-bold text-danger p-2">&#8377;{finalPrice}</span>
        </Descriptions.Item>
      </Descriptions>

      {country === 'IN_India' ? (
        <Drawer
          className="drawer-custom"
          width={window.innerWidth > 900 ? 800 : window.innerWidth - 100}
          visible={isGSTDrawerVisible}
          title="GST Details"
          onClose={hideGSTDrawerHandler}
          footer={null}
          zIndex={9999}
        >
          <Descriptions
            bordered
            className="mb-3"
            style={{ width: '95%' }}
            column={1}
            layout={window.innerWidth > 900 ? 'horizontal' : 'vertical'}
          >
            <Descriptions.Item label="Base Price">
              <span className="h5 p-2">&#8377;{Math.round(basePrice)}</span>
            </Descriptions.Item>

            {state === 'Karnataka' ? (
              <>
                <Descriptions.Item label="CGST @ 9%">
                  <span className="h5 p-2">&#8377;{Math.round(gstAmt / 2)}</span>
                </Descriptions.Item>

                <Descriptions.Item label="SGST @ 9%">
                  <span className="h5 p-2">&#8377;{Math.round(gstAmt / 2)}</span>
                </Descriptions.Item>
              </>
            ) : (
              <Descriptions.Item label="IGST @ 18%">
                <span className="h5 p-2">&#8377;{gstAmt}</span>
              </Descriptions.Item>
            )}
          </Descriptions>

          <span className="h5 p-2">The figures are rounded off to the nearest whole number.</span>
        </Drawer>
      ) : null}

      {defaultCoupon ? (
        <div className="coupon-card mb-6 border-badge-custom">
          <h5 className="font-weight-bold mb-0">Get &#8377;{defaultCoupon?.discountValue} off!</h5>
          <p className="mb-1 font-size-sm">
            Use the coupon code <span className="badge badge-primary-soft">{defaultCoupon?._id}</span>
          </p>
          {specialText ? (
            specialText !== '' ? (
              <p className="mb-0 text-danger font-weight-bold" style={{ fontSize: 13 }}>
                {specialText}
              </p>
            ) : null
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default LeftPanel;
