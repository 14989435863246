import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Space, Spin, Select, Input } from 'antd';
import { AttentionSeeker } from 'react-awesome-reveal';

import {
  onCoupon,
  offCoupon,
  initalCouponCheck,
  onFinishCF,
  marksItemInvalidConfig,
} from '../../store/actions/checkout-actions';
import Countries, { getStatesOfCountry, getCitiesOfState, getMatchingState } from '../../utils/checkoutForm';
import { checkoutActions } from '../../store/reducers/checkout-slice';

const RightPanel = (props) => {
  const dispatch = useDispatch();

  // Item
  const item = useSelector((state) => state.checkout.item);

  // Get the Coupon Query Param
  const coupon = useSelector((state) => state.home.coupon);

  // Get the User Query Params
  const {
    name: userName,
    email: userEmail,
    phone: userPhone,
    confirmEmail: userConfirmEmail,
    redirectUrl: redirectUrl,
  } = useSelector((state) => state.home.user);

  // Get the UTM Query Params
  const utm = useSelector((state) => state.home.utm);

  // Get form-related states
  const [
    couponCode,
    error,
    loading,
    message,
    lock,
    marksItem,
    originalPrice,
    discountedPrice,
    form,
    isCouponReferral,
    focusField,
    focusFieldMessage,
  ] = useSelector((state) => [
    state.checkout.coupon,
    state.checkout.error,
    state.checkout.loading,
    state.checkout.message,
    state.checkout.lock,
    state.checkout.marksItem,
    state.checkout.originalPrice,
    state.checkout.discountedPrice,
    state.checkout.form,
    state.checkout.isCouponReferral,
    state.checkout.focusField,
    state.checkout.focusFieldMessage,
  ]);

  const { _id: itemId, discountedPrice: itemPrice, isGoogleOnly: itemIsGoogleOnly, pg = 'cashfree' } = item;
  const { source, campaign, medium, term } = utm;
  const { country, state, city, class: Class } = form;

  // Form validation rules
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Required.')
      .max(35, 'Maximum length reached (35).')
      .matches(/^[a-zA-Z ]+$/, 'Name must contain only alphabets.'),

    email: Yup.string()
      .required('Email is required.')
      .email('Invalid email.')
      .matches(
        itemIsGoogleOnly ? /^[a-z0-9](\.?[a-z0-9]){5,}@g(oogle)?mail\.com$/ : /^.+@[^.].*\.[a-z]{2,}$/,
        itemIsGoogleOnly ? 'Invalid email (Incorrect email/Only Gmail IDs are allowed).' : 'Invalid email.',
      ),

    confirmEmail: Yup.string()
      .required('Confirm-email is required.')
      .email('Invalid email.')
      .matches(
        itemIsGoogleOnly ? /^[a-z0-9](\.?[a-z0-9]){5,}@g(oogle)?mail\.com$/ : /^.+@[^.].*\.[a-z]{2,}$/,
        itemIsGoogleOnly ? 'Invalid email (Incorrect email/Only Gmail IDs are allowed).' : 'Invalid email.',
      )
      .oneOf([Yup.ref('email'), null], "Emails don't match."),

    phone: Yup.string()
      .required('Mobile number is required.')
      .matches(
        // country === 'IN_India_91' ? /^(?:\+091|\+91|091|91|0)?[6-9]\d{9}$/ : /^\+?(?:[0-9]?){6,14}[0-9]$/,
        country === 'IN_India_91' ? /^(?:0)?[6-9]\d{9}$/ : /^\+?(?:[0-9]?){6,14}[0-9]$/,
        'Invalid mobile number.',
      ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: userName || '',
      email: userEmail || '',
      confirmEmail: userConfirmEmail || '',
      phone: userPhone || '',
    },
    mode: 'onChange',
    shouldFocusError: true,
  };

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
    setError,
    setFocus,
  } = useForm(formOptions);

  const { name: watchName, email: watchEmail, confirmEmail: watchConfirmEmail, phone: watchPhone } = watch();

  const countries = useMemo(() => Countries, []).map((el) => {
    const keyValue = `${el.isoCode}_${el.name}_${el.phonecode}`;
    return (
      <Select.Option key={keyValue} value={keyValue}>
        {el.name}
      </Select.Option>
    );
  });

  const getCodesFromCountry = country.split('_');
  const [countryCode, countryName, phoneCode] = getCodesFromCountry;
  const actualStates = getStatesOfCountry(countryCode);
  const memoizedStates = useMemo(() => actualStates, [actualStates]);

  let states = null;
  if (memoizedStates && memoizedStates.length)
    states = memoizedStates.map((el) => {
      const keyValue = `${el.name}`;
      return (
        <Select.Option key={keyValue} value={keyValue}>
          {el.name}
        </Select.Option>
      );
    });

  const getState = getMatchingState(actualStates, state);
  const stateCode = states && actualStates && getState ? getState.isoCode : null;

  const memoizedCities = useMemo(() => getCitiesOfState(countryCode, stateCode), [countryCode, stateCode]);

  let cities = null;
  if (memoizedCities && memoizedCities.length)
    cities = memoizedCities.map((el) => {
      const keyValue = `${el.name}`;
      return (
        <Select.Option key={keyValue} value={keyValue}>
          {el.name}
        </Select.Option>
      );
    });

  const onSubmitHandler = handleSubmit((data) => {
    dispatch(
      onFinishCF(
        source,
        medium,
        campaign,
        term,
        data.name,
        data.email,
        data.phone,
        itemId,
        countryName,
        state,
        city,
        couponCode,
        lock,
        Class,
        marksItem,
        redirectUrl,
        countryCode,
        phoneCode,
        pg,
      ),
    );
  });

  useEffect(() => {
    const validateFieldsInitially = async () => {
      const triggerFields = [];

      if (watchName) triggerFields.push('name');
      if (watchEmail) triggerFields.push('email');
      if (watchConfirmEmail) triggerFields.push('confirmEmail');
      if (watchPhone) triggerFields.push('phone');

      await trigger(triggerFields, { shouldFocus: true });
    };

    validateFieldsInitially();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    if (coupon) {
      dispatch(initalCouponCheck(coupon, item._id, itemPrice, watchEmail, watchPhone));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, coupon, item._id, itemPrice]);

  useEffect(() => {
    if (couponCode && isCouponReferral && (watchEmail || watchPhone)) {
      dispatch(onCoupon(couponCode, itemId, itemPrice, watchEmail, watchPhone));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, watchEmail, watchPhone, isCouponReferral]);

  useEffect(() => {
    if (marksItem === true) {
      if (!watchEmail || !watchConfirmEmail) dispatch(marksItemInvalidConfig('Email or Confirm Email is missing!'));

      if (watchEmail !== watchConfirmEmail) dispatch(marksItemInvalidConfig("Emails don't match!"));

      if (!redirectUrl) dispatch(marksItemInvalidConfig('Redirect URL is missing!'));
    }
  }, [dispatch, watchEmail, watchConfirmEmail, marksItem]);

  useEffect(() => {
    const focusAField = async () => {
      if (focusField && focusFieldMessage)
        setError(focusField, { type: 'custom', message: focusFieldMessage }, { shouldFocus: true });
      else setFocus(focusField);
    };

    focusAField();

    return () => {
      dispatch(checkoutActions.setFocusField({ focusField: null, focusFieldMessage: '' }));
    };
  }, [focusField, setFocus]);

  const errorFields = [];
  const formValidations = [
    { field: 'Student Name', validationResult: !watchName || errors.name },
    { field: 'Student E-Mail', validationResult: !watchEmail || errors.email },
    {
      field: 'Confirm E-Mail',
      validationResult: !watchConfirmEmail || errors.confirmEmail || watchConfirmEmail !== watchEmail,
    },
    { field: 'Mobile Number', validationResult: !watchPhone || errors.phone },
    {
      field: 'State',
      validationResult: country === 'IN_India_91' && states && !state,
    },
    {
      field: 'City',
      validationResult: country === 'IN_India_91' && cities && !city,
    },
    { field: 'Class', validationResult: !Class },
  ];

  let formHasErrors = false;

  formValidations.forEach((val) => {
    if (val.validationResult) {
      errorFields.push(val.field);
    }

    formHasErrors = formHasErrors || val.validationResult;
  });

  const formHasErrorsMessage = (
    <AttentionSeeker effect="headShake" duration={2500}>
      <p className="mt-6 mb-0 font-size-sm text-danger">
        Before making the payment please check the above field(s):
        <br />
        <span className="font-weight-bold"> {errorFields.join(', ')}</span>
      </p>
    </AttentionSeeker>
  );

  return (
    <div className="col-12 col-lg-7">
      <form onSubmit={onSubmitHandler} className="card p-8 shadow antdCustom">
        {/* Name */}
        <div className="form-group mb-5">
          <label htmlFor="name">
            Student Name <span className="text-danger">*</span>
          </label>
          <input
            id="name"
            type="text"
            {...register('name')}
            className={`form-control ${errors.name ? 'is-invalid' : ''}`}
            placeholder="Enter student name"
          />
          {errors.name && <p className="mt-2 mb-0 font-size-sm text-danger">{errors.name.message}</p>}
        </div>

        {/* Email */}
        <div className="form-group mb-5">
          <label htmlFor="email">
            Student E-Mail <span className="text-danger">*</span>
            <span className="text-primary font-size-sm">&nbsp;(will be used for login details & order updates)</span>
          </label>
          <input
            id="email"
            type="email"
            {...register('email')}
            disabled={marksItem}
            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
            placeholder="Enter student email address"
          />
          {errors.email && <p className="mt-2 mb-0 font-size-sm text-danger">{errors.email.message}</p>}
        </div>

        {/* ConfirmEmail */}
        <div className="form-group mb-5">
          <label htmlFor="confirmEmail">
            Confirm E-Mail <span className="text-danger">*</span>
          </label>
          <input
            id="confirmEmail"
            type="email"
            {...register('confirmEmail')}
            disabled={marksItem}
            autoComplete="none"
            className={`form-control ${errors.confirmEmail ? 'is-invalid' : ''}`}
            placeholder="Enter the same e-mail as above"
          />
          {errors.confirmEmail && <p className="mt-2 mb-0 font-size-sm text-danger">{errors.confirmEmail.message}</p>}
        </div>

        {/* Phone */}
        <div className="form-group mb-5">
          <label htmlFor="phone">
            Mobile Number <span className="text-danger">*</span>
            <span className="text-primary font-size-sm"> (without country code)</span>
          </label>
          <input
            id="phone"
            type="number"
            {...register('phone')}
            className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
            placeholder="Enter mobile number"
            // ref={phoneInputRef}
          />
          {errors.phone && <p className="mt-2 mb-0 font-size-sm text-danger">{errors.phone.message}</p>}
        </div>

        {/* Class Dropdown */}
        <div className="form-group mb-5">
          <label htmlFor="class">
            Class <span className="text-danger">*</span>
          </label>

          <br />
          <Select
            id="class"
            size="large"
            placeholder="Select Your Class"
            style={{ width: '100%' }}
            value={Class}
            onChange={(e) => dispatch(checkoutActions.updateClass(e))}
            showSearch
          >
            <Select.Option key="class12" value="class12">
              Class 12
            </Select.Option>
            <Select.Option key="firstTimeDropper" value="firstTimeDropper">
              1st Time Dropper
            </Select.Option>
            <Select.Option key="secondTimeDropper" value="secondTimeDropper">
              2nd Time Dropper
            </Select.Option>
            <Select.Option key="partialDropper" value="partialDropper">
              Partial Dropper
            </Select.Option>
            <Select.Option key="class11" value="class11">
              Class 11
            </Select.Option>
            <Select.Option key="class10" value="class10">
              Class 10
            </Select.Option>
          </Select>
        </div>

        {/* Country Dropdown */}
        <div className="form-group mb-5">
          <label htmlFor="country">
            Country <span className="text-danger">*</span>
          </label>

          <br />
          <Select
            id="country"
            size="large"
            placeholder="Select Country"
            style={{ width: '100%' }}
            value={country}
            onChange={(e) => dispatch(checkoutActions.updateCountry(e))}
            showSearch
          >
            {countries}
          </Select>
        </div>

        {/* State Dropdown */}
        <div className="form-group mb-5">
          <label htmlFor="state">
            State&nbsp;
            {country === 'IN_India_91' ? <span className="text-danger">*</span> : null}
          </label>
          <br />
          <Select
            id="state"
            size="large"
            placeholder="Select State"
            style={{ width: '100%' }}
            value={state}
            onChange={(e) => dispatch(checkoutActions.updateState(e))}
            showSearch
          >
            {states || <Select.Option>No states available</Select.Option>}
          </Select>
        </div>

        {/* City Dropdown */}
        <div className="form-group mb-5">
          <label htmlFor="city">
            City&nbsp;
            {country === 'IN_India_91' ? <span className="text-danger">*</span> : null}
          </label>
          <br />
          <Select
            id="city"
            size="large"
            placeholder="Select City"
            style={{ width: '100%' }}
            value={city}
            onChange={(e) => dispatch(checkoutActions.updateCity(e))}
            showSearch
          >
            {states && !state ? (
              <Select.Option>Please select a state first</Select.Option>
            ) : cities ? (
              cities
            ) : (
              <Select.Option>No cities available</Select.Option>
            )}
          </Select>
        </div>

        {/* Coupon */}
        <div className="form-group mb-md-5 mb-2">
          <label htmlFor="coupon">Coupon</label>
          <div className="row">
            <div className="col-12 col-md-7 mb-md-3 mb-0">
              <div className="input-group">
                <Input
                  id="coupon"
                  type="text"
                  className={
                    error ? (error === true ? 'form-control is-invalid' : 'form-control is-valid') : 'form-control'
                  }
                  style={{ borderRadius: '0.375rem' }}
                  placeholder="Coupon Code"
                  disabled={lock}
                  maxLength={15}
                  allowClear
                  value={couponCode}
                  onChange={(e) => dispatch(checkoutActions.updateCoupon({ coupon: e.target.value }))}
                />
              </div>
            </div>
            <div className="col-12 col-md-5 d-flex justify-content-center">
              <div className="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id="button-addon2"
                  onClick={() => {
                    if (lock) dispatch(offCoupon(itemPrice));
                    else {
                      if (couponCode) dispatch(onCoupon(couponCode, itemId, itemPrice, watchEmail, watchPhone));
                    }
                  }}
                >
                  {lock ? 'Clear Coupon' : 'Apply Coupon'}
                </button>
              </div>
            </div>
          </div>

          {message ? (
            error ? (
              <p className="mt-2 mb-0 font-size-sm text-danger">{message}</p>
            ) : (
              <p className="mt-2 mb-0 font-size-sm text-success" style={{ color: 'green' }}>
                {message}
              </p>
            )
          ) : null}
        </div>

        {loading ? (
          <>
            <Space style={{ display: 'flex', justifyContent: 'center' }}>
              <span style={{ fontSize: 18 }}>
                <b>Please wait...</b>
              </span>

              <Spin
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '2%',
                }}
              />
            </Space>
          </>
        ) : (
          <>
            <button
              id="paymentButton"
              ref={props.fwdRef}
              className={`btn btn-block ${formHasErrors ? '' : 'btn-primary'}`}
              type="submit"
              disabled={formHasErrors}
            >
              Pay &#8377;{discountedPrice ? discountedPrice : originalPrice}
            </button>

            {formHasErrors ? formHasErrorsMessage : null}
          </>
        )}
      </form>
    </div>
  );
};

export default RightPanel;
