import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';

import Page404 from '../../pages/404';
import Header from '../Header.bs';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import CCAvenueForm from './CCAvenueForm';

import { fetchItem } from '../../store/actions/checkout-actions';

const Checkout = ({ itemId }) => {
  const dispatch = useDispatch();
  const rightPanelPaymentBtn = useRef(null);

  const [item, loading] = useSelector((state) => [state.checkout.item, state.checkout.loading]);
  const ccaForm = useSelector((state) => state.checkout.ccaForm);

  useEffect(() => {
    if (itemId) dispatch(fetchItem(itemId));
  }, [dispatch, itemId]);

  const scrollToPayment = (toRef) => {
    if (toRef) toRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      {!itemId ? (
        <Page404 type="404" />
      ) : (
        <>
          {loading ? (
            <Spin size="large" className="loadingSpinner" />
          ) : (
            <>
              {ccaForm ? (
                <CCAvenueForm
                  formActionUrl={ccaForm.formActionUrl}
                  encryptedData={ccaForm.encRequest}
                  access_code={ccaForm.accessCode}
                />
              ) : item ? (
                <>
                  <Header />

                  <section className="section py-lg-8 py-md-4 py-4">
                    <div className="container d-flex flex-column">
                      <div className="row justify-content-center no-gutters">
                        <LeftPanel />

                        <button
                          className="btn btn-block btn-primary mb-6 scrollToPaymentBtn"
                          type="button"
                          onClick={() => scrollToPayment(rightPanelPaymentBtn)}
                        >
                          Click to Pay now!
                        </button>

                        <RightPanel fwdRef={rightPanelPaymentBtn} />
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <Page404 type="404" />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Checkout;
